<template>
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu"
    crossorigin="anonymous"
  />

  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <style-prep></style-prep>
</template>

<script>
import StylePrep from "./components/StylePrep.vue";

export default {
  name: "App",
  components: {
    StylePrep,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  box-sizing: border-box;
  display: block;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-size-adjust: 100%;
  color: rgb(33, 37, 41);
  background-color: rgb(255, 255, 255);
  -webkit-tap-highlight-color: rgb(0, 0, 0);
}

#app {
  margin-left: auto;
  margin-right: auto;
}

#app.\31 25\% {
  width: 100%;
  max-width: 1750px;
  min-width: 1400px;
}

#app.\37 5\% {
  width: 1050px;
}

#app.\35 0\% {
  width: 700px;
}

#app.\32 5\% {
  width: 350px;
}

#app {
  width: 65%;
}

@media screen and (min-width: 961px) and (max-width: 1880px) {
  #app.\31 25\% {
    width: 100%;
    max-width: 1500px;
    min-width: 1200px;
  }

  #app.\37 5\% {
    width: 900px;
  }

  #app.\35 0\% {
    width: 600px;
  }

  #app.\32 5\% {
    width: 300px;
  }

  #app {
    width: 1200px;
  }
}
@media screen and (min-width: 961px) and (max-width: 1620px) {
  #app.\31 25\% {
    width: 100%;
    max-width: 1200px;
    min-width: 960px;
  }

  #app.\37 5\% {
    width: 720px;
  }

  #app.\35 0\% {
    width: 480px;
  }

  #app.\32 5\% {
    width: 240px;
  }

  #app {
    width: 960px;
  }
}
@media screen and (min-width: 961px) and (max-width: 1320px) {
  #app.\31 25\% {
    width: 100%;
    max-width: 125%;
    min-width: 100%;
  }

  #app.\37 5\% {
    width: 75%;
  }

  #app.\35 0\% {
    width: 50%;
  }

  #app.\32 5\% {
    width: 25%;
  }

  #app {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  #app.\31 25\% {
    width: 100%;
    max-width: 125%;
    min-width: 100%;
  }

  #app.\37 5\% {
    width: 75%;
  }

  #app.\35 0\% {
    width: 50%;
  }

  #app.\32 5\% {
    width: 25%;
  }

  #app {
    width: 100%;
  }
}
@media screen and (max-width: 736px) {
  #app.\31 25\% {
    width: 100%;
    max-width: 125%;
    min-width: 100%;
  }

  #app.\37 5\% {
    width: 75%;
  }

  #app.\35 0\% {
    width: 50%;
  }

  #app.\32 5\% {
    width: 25%;
  }

  #app {
    width: 100% !important;
  }
}
</style>
