<template>
  <h1>AI-Masterpiece presents. . .</h1>
  <h2>Neural Style Transfer</h2>
  <img class="header-sample" src="../assets/sydney_rain-princess-3e4.png" />
  <img class="header-sample" src="..\assets\sydney_cool-paint-1e4.png" />
  <img class="header-sample" src="../assets/sydney.png" />
  <img class="header-sample" src="..\assets\sydney_graffiti-3-fish-1e5.png" />
  <img class="header-sample" src="..\assets\sydney_color-blending-1e5.png" />
  <p>To begin, upload an image, select a template, and submit. Thats it!</p>
  <form @submit.prevent="selectImage">
    <div>
      <section>
        <h2>Exposition</h2>
        <p>
          I'm more of a computer scientist than an artist (I mean check out this
          page!), but with these neural nets, you can dress up your photos or
          find flashy inspiration anywhere!
        </p>
        <p>
          There are a number of trained models available, and each have multiple
          versions with differing balances of focus on the style or on the
          content you provide.
        </p>
        <h3>What are these options?</h3>
        <p>
          The content weight/bias can be determined selected in a dropdown, and
          the numbers are set up like this: 1e4, 1e5, 3e4, etc. Think of it as
          "first number"(e)"with this many 0's behind it". So 1e4=>10,000,
          1e5=>100,000, and 3e4 is roughly a multiple of 3 between them at
          30,000.
        </p>
        <p>
          Try a bunch and see what works best. I'm operating these kind of on
          the cheap, so they're not fast, but you can go reasonably wild without
          bankrupting me. Most models will do best with content weights in the
          range of 1e4, 3e4, and 1e5. Go higher to preserve finer detail, go
          lower to 'get creative'
        </p>
        <p>
          3e3 produces some interesting blotches. 1e6 (on some of them) are
          color heightening 'glow-ups'.
        </p>
      </section>
    </div>
    <div>
      <section>
        <h3>Experiment with the models in just a few steps</h3>
        <ul class="nodot">
          <li>1. <span class="text-muted">Select an image to upload</span></li>
          <li>
            2.
            <span class="text-muted">Choose one of the available models</span>
          </li>
          <li>3. <span class="text-muted">Select a content bias</span></li>
          <li>4. <span class="text-muted">Click run!</span></li>
        </ul>
      </section>
    </div>
    <hr />
    <div class="gridish">
      <label><span class="text-muted">Upload an image</span></label>
      <input
        style="display: inline-block"
        type="file"
        @change="selectImage" accept="image/*"
        required
      />

      <label> <span class="text-muted">Select model, weight</span></label>
      <span style="text-align: left"
        ><select v-model="selectedModel" @change="selectedWeight = ''">
          <option v-for="model in modelList" :key="model">
            {{ model }}
          </option>
        </select>
        <select v-model="selectedWeight" :disabled="weightList.length == 0">
          <option v-for="weight in weightList" :key="weight">
            {{ weight }}
          </option>
        </select></span
      >

      <label><span class="text-muted">Submit</span></label>
      <button
        class="btn btn-primary btn-block"
        type="submit"
        @click="StylizeImage"
        :disabled="running || selectedModel.length == 0 || selectedWeight.length == 0 || !file"
      >
        Run{{running ? "ning..." : " the model"}}
      </button>
    </div>
  </form>
  <footer>
    <h4>How does this <i>style transfer algorithm</i> work?</h4>
    <p><i>Coming soon!</i></p>
    <h4>How does this <i>website/service</i> work?</h4>
    <p>
      Serverless is the magic word here. I host this page, I pay for the compute
      cost of the conversion, but I don't have dedicated servers running all the
      time waiting for your call. I use AWS Lambda and API Gateway to serve the
      algorithm, so when you submit a photo, they spin up, do the work and
      likely just spin back down. I get charged for 6 seconds of compute.
    </p>
    <p>
      The images aren't saved either. They're always just kept in memory from
      the time you hit "Run" until they're returned and you save the results.
    </p>
    <br /><br />
    <h4>If you:</h4>
    <ul class="" style="width: auto; list-style-position: inside">
      <li>would like to see more weights for a specific model</li>
      <li>
        have an image with a particularly rad style which you think a model
        would pick up on
      </li>
      <li>have an image which you'd like to offer as a sample/demo piece</li>
      <li>OR would like to reach out and say hi</li>
    </ul>
    <p>
      feel free to e-mail me at
      <a href="mailto:nathan@tarbox.tech">nathan@tarbox.tech</a> You can also
      see a few of my other rantings and artwork on my neglected blog @
      <a href="tarbox.tech">tarbox.tech</a>
    </p>
  </footer>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      file:null,
      selectedModel: "",
      selectedWeight: "",
      fullModelList: {},
      running: false
    };
  },
  computed: {
    modelList() {
      return Object.keys(this.fullModelList);
    },
    weightList() {
      let rtn = [];
      if (this.selectedModel != null && this.selectedModel.length > 0) {
        rtn = this.fullModelList[this.selectedModel];
      }
      return rtn;
    },
  },
  created() {
    this.getModelList();
    //   this.$watch(
    //       () => this.$route.params,
    //       () => {
    //           this.getModelList()
    //       },
    //       {immediate:true}
    //   )
  },
  methods: {
    getModelList() {
      document.title = "Neural Style Transfer";
      axios
        .post(
          `https://9vjso0dmij.execute-api.us-east-1.amazonaws.com/test/model-list`
        )
        .then((response) => {
          if (response.status != "200") {
            alert("Uh oh, not a 200 return");
            return;
          }
          // console.log("Response follows");
          // console.log(response);
          this.fullModelList = response.data;
          // eslint-disable-next-line no-unused-vars
        })
        .catch(() => {
          console.warn("Not good man :(");
          alert(
            "Uh oh, can't find the models.\nContact Nathan\nSorry 'bout that."
          );
        });
    },
    clearWeight() {
      this.selectedWeight = "";
    },
    selectImage(e) {
      if (e.target.files[0].size > 10000000) {
        e.target.value = "";
        alert("That image is too big for this service, try resizing it first.")
      }
      
      this.file = e.target.files[0]; 
    },
    StylizeImage(e) {
      try {
      this.running = true;
      e.preventDefault();
      var fd = new FormData();
      fd.append("src", this.file);
      fd.append("model", this.selectedModel);
      fd.append("lvl", this.selectedWeight);
      var endName = this.file.name;
      endName = endName.substring(0, endName.lastIndexOf('.')) + "-" +       this.selectedModel +        "_" +         this.selectedWeight +        ".png";
      } catch(error) {
        this.running = false;
        return;
      }
      axios
        .post(
          `https://9vjso0dmij.execute-api.us-east-1.amazonaws.com/test/stylize-image`,
          fd
        )
        .then((response) => {
          this.running = false;
          const blob = base64toBlob(response.data, "image/png");
          const url = window.URL.createObjectURL(blob);

          var fileLink = document.createElement("a");

          fileLink.href = url;
          fileLink.text = "Your new download";
          fileLink.setAttribute("download", endName);
          document.body.appendChild(fileLink);

          fileLink.click();
          document.body.removeChild(fileLink);
          // window.open(url) // Mostly the same, I was just experimenting with different approaches
        })
        .catch(() => {
          this.running = false;
          console.warn("Not good man :(");
          alert(
            "Uh oh, something's broken.\nContact Nathan\nSorry 'bout that."
          );
        });
    },
  },
};

function base64toBlob(base64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
</script>

<style>
div {
  margin: 1rem 0;
  border: 1px solid #ccc;
  padding: 1rem;
}
ul {
  margin-left: auto;
  margin-right: auto;
}

ul.nodot {
  list-style-type: none;
}
div.gridish {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
  justify-content: center;
}
div.gridish label {
  text-align: right;
}
.header-sample {
  width: 20%;
}
</style>
